import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import io from 'socket.io-client';
// import ProgressBar from './ProgressBar'; // Import the ProgressBar component

const UploadSection = () => {
  const [file, setFile] = useState(null);
  // const [progress, setProgress] = useState(0);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  // const [uploadPhase, setUploadPhase] = useState('initial');
  const socketRef = useRef(null);


  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    console.log("inside select files")
  };

  const handleUpload = async () => {
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);
    const token = sessionStorage.getItem('token');
    setLoading(true);
    // setUploadPhase('initial'); // Start with the initial phase

    try {

      const uploadResponse = await axios.post(
        'https://api.avaysingh.com/upload',
        formData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round((progressEvent.loaded * 50) / progressEvent.total);
            console.log('progress is :',progress);
            setUploadProgress(progress);


            // Start WebSocket connection when we hit 50% progress
            if (progress >= 50 && !socketRef.current){
              // setUploadPhase('websocket');

              startSocketConnection();
            }
          },

        }
      );

      const { file_id } = uploadResponse.data;
      console.log('file uploaded successfully')
      sessionStorage.setItem('file_id', file_id);

    } catch (error) {
      console.error('Error uploading file:', error);
      // setLoading(false);
    }
  };

  const startSocketConnection = () => {

    socketRef.current = io('https://api.avaysingh.com',{
      transports: ['websocket', 'polling'], // Ensure compatibility with all transport options
      reconnection: true, // Allow the client to reconnect automatically
      // reconnectionAttempts: 10, // Number of reconnection attempts before giving up
      reconnectionDelay: 5000, // Time between reconnection attempts
    });

    socketRef.current.on('connect', () => console.log("Connected to WebSocket"));

    // Listen to upload progress events
    socketRef.current.on('upload-progress-message', (data) => {
      console.log("recived data", data);
      if(data.progress){
        setUploadProgress(50 + data.progress / 2);
      }
      
      if (data.progress >= 100) {
        setUploadProgress(100);       
        socketRef.current.disconnect();
      }
    });

    socketRef.current.on('disconnect', () => {
      console.log("Disconnected from WebSocket");
       
      setTimeout(()=> {
        setUploadProgress(0);
        setLoading(false);
      },1000);
      socketRef.current = null;
    });

  };

  return (
    <Section>
      <SectionTitle>Upload Files</SectionTitle>
      <UploadBox>
        <Icon src="path_to_upload_icon.png" alt="Upload Icon" />
        {/* <UploadMessage>Drag and drop files, or <BrowseLink href="#">Browse</BrowseLink></UploadMessage> */}
        <UploadMessage>
          Drag and drop files, or <BrowseLink>Browse</BrowseLink>
        </UploadMessage>
        <SupportMessage>Support video and audio files</SupportMessage>

        {/* <input type="file" onChange={handleFileChange} />
        <button onClick={handleUpload}>Upload</button>
        {loading && <ProgressBar progress={progress} />} */}

        <FileInput type="file" onChange={handleFileChange} />
        <UploadButton onClick={handleUpload} disabled={!file}>
          Upload
        </UploadButton>
        {loading && ( 
          <ProgressBar>
            <Progress progress={uploadProgress} />
            <ProgressText>{uploadProgress}%</ProgressText>
          </ProgressBar>
        )} 
      </UploadBox>
    </Section>
  );
}

const Section = styled.div`
  margin: 20px 0;
`;

const SectionTitle = styled.h2`
  margin-bottom: 15px;

  font-size: 24px;
  color: #333;

`;

const UploadBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  // background-color: white;
  // border: 2px dashed #ccc;
  // border-radius: 8px;

  background-color: #f9f9f9;
  border: 2px dashed #007bff;
  border-radius: 12px;

  text-align: center;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

`;

const Icon = styled.img`
  // width: 50px;
  // height: 50px;
  // margin-bottom: 10px;

  width: 60px;
  height: 60px;
  margin-bottom: 20px;

`;

const UploadMessage = styled.div`
  font-size: 16px;
  color: #555;
`;

const BrowseLink = styled.span`
  color: #007bff;
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;

  &:hover {
    color: #0056b3;
  }
`;

// const BrowseLink = styled.a`
//   color: #007BFF;
//   cursor: pointer;
// `;

const SupportMessage = styled.p`
  // margin-top: 5px;
  // font-size: 14px;

  margin-top: 10px;
  font-size: 14px;
  color: #777;
`;

const FileInput = styled.input`
  margin-top: 20px;
  cursor: pointer;
  font-size: 16px;
`;

const UploadButton = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  outline: none;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const ProgressBar = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
  margin-top: 20px;
  overflow: hidden;
`;

const Progress = styled.div`
  width: ${({ progress }) => progress}%;
  height: 100%;
  background-color: #007bff;
  transition: width 0.4s ease;
`;

const ProgressText = styled.span`
  position: absolute;
  top: -25px;
  right: 10px;
  font-size: 12px;
  color: #555;
`;

export default UploadSection;