import React from 'react';
import styled from 'styled-components';
import WelcomeSection from './WelcomeSection';
import UploadSection from './UploadSection';
import FileListing from './FileListing';

const MainContent = ({ username }) => {
  return (
    <MainContainer>
      <WelcomeSection username={username} />
      <UploadSection />
      <FileListing />
    </MainContainer>
  );
}

const MainContainer = styled.div`
  flex: 1;
  padding: 20px;
  background-color: #F8F8F8;
`;

export default MainContent;