import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import crypto from 'crypto';
import DOMPurify from 'dompurify';

const FileListing = () => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [summary, setSummary] = useState('');

  const token = sessionStorage.getItem('token');

  const generateHash = (file) => {
    const data = `${file.file_name}-${file.file_path}-${file.upload_time}`;
    return crypto.createHash('sha256').update(data).digest('hex');
  };

  const fetchFiles = useCallback(async () => {
    try {
      const filesResponse = await axios.get('https://api.avaysingh.com/files', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      console.log('Files Response:', filesResponse.data);
      // Ensure uploadedFiles is always an array
      setUploadedFiles(Array.isArray(filesResponse.data.files) ? filesResponse.data.files : []);
    } catch (error) {
      console.error('Error fetching files:', error);
      setUploadedFiles([]); // Ensure uploadedFiles is always an array
    }
  }, [token]);

  useEffect(() => {
    fetchFiles();
  }, [fetchFiles]);

  const fetchSummary = async (file_path) => {
    try {
      const summaryResponse = await axios.get(`https://api.avaysingh.com/summary?file_id=${encodeURIComponent(file_path)}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      setSummary(summaryResponse.data.summary);
    } catch (error) {
      console.error('Error fetching summary:', error);
    }
  };

  const handleFileClick = (file_path) => {
    fetchSummary(file_path);
  };

  const formatSummary = (text) => {
    if (!text) return '';
    let formattedText = text.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');
    formattedText = formattedText.replace(/\n/g, '<br>');
    return DOMPurify.sanitize(formattedText);
  };

  return (
    <Section>
      <SectionTitle>Your Files</SectionTitle>
      <Table>
        <thead>
          <TableRow>
            <TableHeader>File Name</TableHeader>
            <TableHeader>File Path</TableHeader>
            <TableHeader>Last Modified</TableHeader>
          </TableRow>
        </thead>
        <tbody>
          {uploadedFiles.map((file) => (
            // <TableRow key={file.file_name} onClick={() => handleFileClick(file.file_path)}>
            <TableRow key={generateHash(file)} onClick={() => handleFileClick(file.file_path)}>
              <TableCell>{file.file_name}</TableCell>
              <TableCell>{file.file_path}</TableCell>
              <TableCell>{new Date(file.upload_time).toLocaleDateString()}</TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
      {summary && (
        <SummaryBox>
          <h3>File Summary</h3>
          <div className="summary" dangerouslySetInnerHTML={{ __html: formatSummary(summary) }} />
        </SummaryBox>
      )}
    </Section>
  );
};

const Section = styled.div`
  margin: 20px 0;
`;

const SectionTitle = styled.h2`
  margin-bottom: 20px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableRow = styled.tr`
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const TableHeader = styled.th`
  padding: 10px;
  background-color: #f0f0f0;
  text-align: left;
  border-bottom: 2px solid #ccc;
`;

const TableCell = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ccc;
`;

const SummaryBox = styled.div`
  margin-top: 20px;
  padding: 20px;
  background-color: #f8f8f8;
  border: 1px solid #ccc;
  border-radius: 8px;
`;

export default FileListing;