import React from 'react';
import styled from 'styled-components';

const WelcomeSection = ({ username }) => {
  return (
        <Section>
            <WelcomeMessage>Welcome Back, {username}</WelcomeMessage>
        </Section>
  );
};

const Section = styled.div`
  margin: 20px 0;
`;

const WelcomeMessage = styled.h1`
  font-size: 24px;
`;

export default WelcomeSection;