import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const Header = ({ onLogout }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    onLogout();
    navigate('/');
  };

  return (
    <HeaderContainer>
      <Logo>MINO</Logo>
      <NavMenu>
        <NavLink>Home</NavLink>
        <NavLink>About</NavLink>
        <NavLink>Features</NavLink>
        <NavLink>Pricing</NavLink>
        <NavLink>Contact</NavLink>
        <UserSection>
          <UserAvatar src="path_to_user_avatar" alt="User Avatar" />
          <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
        </UserSection>
      </NavMenu>
    </HeaderContainer>
  );
}

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #1C1C1E;
  color: white;
`;

const Logo = styled.div`
  font-size: 24px;
  font-weight: bold;
`;

const NavMenu = styled.nav`
  display: flex;
  align-items: center;
`;

const NavLink = styled.a`
  margin: 0 15px;
  cursor: pointer;
`;

const UserSection = styled.div`
  display: flex;
  align-items: center;
`;

const UserAvatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-right: 15px;
`;

const LogoutButton = styled.button`
  padding: 10px 20px;
  background-color: #FF4136;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

export default Header;