import React from 'react';
import styled from 'styled-components';
import Header from '../components/Header';
import MainContent from '../components/MainContent';

const Dashboard = ({ username, onLogout }) => {
  return (
    <DashboardContainer>
      <Header onLogout={onLogout} />
      <BodyContainer>
        <MainContent username={username} />
      </BodyContainer>
    </DashboardContainer>
  );
}

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const BodyContainer = styled.div`
  display: flex;
  flex: 1;
`;

export default Dashboard;